<template>
  <div class="component-wrap">
    <a-tabs class="mine-tabs" v-model:activeKey="activeKey">
      <a-tab-pane key="1" :tab="$t('face.pending_face')">
        <!-- 待完成面授 -->
        <div>
          <faceNext />
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('face.history_face')">
        <!-- 历史面授 -->
        <div>
          <faceOver />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import faceNext from "./face/faceNext.vue";
import faceOver from "./face/faceOver.vue";
export default {
  components: {
    faceNext,
    faceOver,
  },
  setup() {
    const state = reactive({
      activeKey: "1",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
