<template>
  <div class="filter-wrap" id="pageTop">
    <!-- 学习状态 -->
    <ListFilter
      only
      :name="$t('face.study_state')"
      :dataSource="reviewStatus"
      @onChange="switchStatus"
    >
      <template v-slot:right>
        <div class="search">
          <a-input-search
            v-model:value="name"
            :placeholder="$t('face.please_enter')"
            style="width: 220px"
            enter-button
            allow-clear
            @search="onSearch"
          />
          <!-- 请输入面授名称 -->
        </div>
      </template>
    </ListFilter>
    <!-- 面授类型 -->
    <ListFilter
      only
      :name="$t('face.type')"
      :dataSource="faceType"
      @onChange="switchFaceType"
    />
  </div>
  <div class="list-type-wrap">
    <ListType :checked="listType" @change="(type) => (listType = type)" />
  </div>
  <a-spin :spinning="listLoading">
    <div class="content-wrap">
      <template v-if="dataList.length">
        <div
          class="content"
          :class="{ card: listType == 'card', list: listType == 'list' }"
        >
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <FaceCard
              :timeType="2"
              :newWindow="newWindow == 1"
              :listType="listType"
              :dataSource="item"
            />
          </div>
        </div>
        <div class="page-Wrap">
          <a-pagination
            show-quick-jumper
            hideOnSinglePage
            :defaultPageSize="10"
            v-model:current="currentPage"
            :total="pageTotal"
            @change="pageChange"
          >
            <template #itemRender="{ type, originalElement }">
              <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
              <a class="page-a" v-else-if="type === 'next'">{{
                $t("cm_next")
              }}</a>
              <renderVNode v-else :vnode="originalElement"></renderVNode>
            </template>
          </a-pagination>
        </div>
      </template>
      <a-empty
        v-if="!dataList.length && !listLoading"
        :image="require('@/assets/image/no_data_3.png')"
        :image-style="{ height: '186px' }"
        style="padding: 60px 0"
      >
        <template #description>
          <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
        </template>
      </a-empty>
    </div>
  </a-spin>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getMyFaceList } from "@/api/train";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import FaceCard from "@/components/new/mine/FaceCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    FaceCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      faceType: [
        { id: 1, name: $t("face.online") }, // 线上授课
        { id: 2, name: $t("face.offline") }, // 线下授课
        { id: 3, name: $t("face.mix") }, // 混合授课
      ],
      reviewStatus: [
        { id: 3, name: $t("CM_NoTakePartIn") }, // 未参与
        { id: 2, name: $t("CM_NotCompleted") }, // 未完成
        { id: 1, name: $t("CM_Completed") }, // 已完成
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      name: "",
      complete: 0,
      type: 0,
      listType: "list",
      listLoading: true,
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.listType = company.useUserCenterShow == 2 ? "card" : "list";
      },
      { immediate: true }
    );

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getMyFaceList({
        timeType: 2,
        page: state.currentPage,
        pageSize: 10,
        name: state.name,
        complete: state.complete,
        trainType: state.type,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.complete = arr[0];
      reloadList();
    };

    const switchFaceType = (arr) => {
      state.type = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      switchStatus,
      switchFaceType,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
</style>
